<template>
  <div style="position: relative">
    <div class="width_1434 bg_white">
      <!-- Swiper -->

      <div class="recommendPage" v-if="contestTeamList.length > 0">
        <swiper :options="swiperOption" ref="mySwiper">
          <swiper-slide v-for="(i, index) in contestTeamList" :key="index">
            <div class="swiper-slidebox">
              <div class="sloderbox" :style="{'background': 'url('+projectImgList[index]+') no-repeat','backgroundSize':'100%'}">
                <div class="felx_box project-list">
                  <div>
                    <p>{{ i.contestProjectName }}</p>
                    <p class="project-list-time">6月1日</p>
                  </div>
                  <div>
                    <!-- <p>第{{ i.contestGroup }}组</p> -->
                    <p class="project-list-hc">候场</p>
                  </div>
                </div>
                <!-- <div class="time">{{i.startTime}}</div> -->
              </div>
              <div class="shool_name">
                <div v-for="(time, p) in i.contestTeamList" :key="p" :title="time.schoolSimplifyName + '幼儿园'" >
                  {{ time.schoolSimplifyName }}幼儿园
                </div>
              </div>
            </div>
          </swiper-slide>

          <div class="swiper-button-prev" slot="button-prev">
            <i class="el-icon-arrow-left"></i>
          </div>
          <div
            class="swiper-button-next"
            slot="button-next"
            style="height: 1.22rem"
          >
            <i class="el-icon-arrow-right"></i>
          </div>
        </swiper>
      </div>

      <!-- <div class="signup" @click="signup" >
      
     </div> -->
      <div class="signup">
        <swiper ref="mySwiper" :options="swiperOptions">
          <swiper-slide
            v-for="item in centerImgList"
            :key="item.id"
            >
            <img :src="item.url" width="100%" height="100%" alt="">
          </swiper-slide>
          <!-- <div class="swiper-pagination" slot="pagination"></div> -->
        </swiper>
      </div>
      
      <!-- 活动 -->
      <div class="padding_40">
        <div>
          <div class="img_box">
            <swiper :options="swiperOptionteo" ref="mySwipertwo">
              <swiper-slide v-for="(i, index) in projectlist" :key="index">
                <div class="swperimg" @click="gonewlist(i.id)">
                  <img :src="constant.URL + i.coverImage" alt="" />
                </div>
              </swiper-slide>

              <!-- Add Pagination -->
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
          <div class="team-list">
            <img src="../../assets/img/sports/Pic_Sy_Team_b.png" alt="">
          </div>
          <!-- <div class="ranking" v-if="teamtodaylist.length">
            <div class="ranking_title">
              <i></i>
              <span>参赛队伍</span>
            </div>
            <div class="felx_box">
              <div
                class="ringtime"
                v-for="(i, index) in teamtodaylist"
                :key="index"
              >
                <div class="img_border">
                  <img :src="constant.URL + i.schoolLogoImage" alt="" />
                </div>
                <div>{{ i.schoolSimplifyName }}</div>
              </div>
            </div>
          </div> -->
        </div>
        <div class="padding_40right">
          <div class="margin_text_bottom">
            <div class="margin_text_bottom-tit">
              <p style="cursor:default">赛事新闻</p>
              <!-- <p @click="gonewlist(eventTop.id)" v-if="eventTop">{{eventTop.contestNewsName}}</p> -->
              <p @click="gonews(1)" v-if="Eventnews.length > 7">更多</p>
            </div>
            <div class="list_text">
              <div
                v-for="(i, index) in Eventnews"
                :key="index"
                @click="gonewlist(i.id)"
              >
                <p v-if="index <= 6 && i.showType != 2">{{ i.contestNewsName }}</p>
              </div>
              <!-- <div
                class="gengduo"
                v-if="Eventnews.length > 6"
                @click="gonews(1)"
              >
                更多>> -->
              <!-- </div> -->
              <!-- <div class="gengduo"  @click="gonews(1)">更多>></div> -->
            </div>
            <!-- <div>
            <el-button type="primary" @click="signUp">报名</el-button>
          </div> -->
          </div>
          <div class="margin_text_bottom">
            <div class="margin_text_bottom-tit">
              <p style="cursor:default">精彩花絮</p>
              <!-- <p @click="gonewlist(huabianTop.id)" v-if="huabianTop">{{huabianTop.contestNewsName}}</p> -->
              <p @click="gonews(2)" v-if="huabianlist.length > 6">更多</p>
            </div>
            <div class="list_text">
              <div
                v-for="(i, index) in huabianlist"
                :key="index"
                @click="gonewlist(i.id)"
              >
                <p v-if="index <= 5 && i.showType != 2">{{ i.contestNewsName }}</p>
              </div>
              <!-- <div
                class="gengduo"
                v-if="huabianlist.length > 6"
                @click="gonews(2)"
              >
                更多>>
              </div> -->
            </div>
          </div>
        </div>
        <div class="rank-list">
          <img src="../../assets/img/sports/Pic_JFPH1.png" alt="" @click="toJump('sportsRank')">
        </div>
      </div>
      <!-- <div style="height: 94px"></div> -->
      <div class="project-detail">
        <div class="project-detail-tit">
          <img src="../../assets/img/sports/Pic_Title_XMJS.png" alt="">
        </div>
        <div class="project-detail-dec">
          <img src="../../assets/img/sports/Pic_XMTP.png" alt="" @click="toJump('project')">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  components: {
    swiper,
    swiperSlide,
  },
  watch: {
    "$store.state.spotrsindex"(newval, olval) {
      console.log(olval);
      if (newval) {
        this.getlist();
        this.newslistbtn(3); //轮播新闻
        this.teamtoday(); //今日参赛队伍
        this.newslistbtn(1); //赛事新闻
        this.newslistbtn(2); //花边新闻
      }
    },
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 30,

        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOptionteo: {
        // autoplay: {delay: 3000,disableOnInteraction: false}, // 手动切换之后继续自动轮播},
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          //clickable: true // 允许点击小圆点跳转
        },
      },
      swiperOptions: {
        autoplay: {
          disableOnInteraction: false,
          delay: 5000,
        },
        // pagination: {
        //   el: '.swiper-pagination',    //与slot="pagination"处 class 一致
        //   clickable: true,            //轮播按钮支持点击
        // }
      },
      contestTeamList: [],
      teamtodaylist: [],
      projectlist: [],
      Eventnews: [],
      eventTop:{},
      huabianlist: [],
      huabianTop:{},
      projectImgList:[
        require('../../assets/img/sports/Pic_Bg_Fz_XHC.png'),
        require('../../assets/img/sports/Pic_Bg_Fz_QWGH.png'),
        require('../../assets/img/sports/Pic_Bg_Fz_FSYL.png'),
        require('../../assets/img/sports/Pic_Bg_Fz_QXXL.png'),
        require('../../assets/img/sports/Pic_Bg_Fz_WRYQ.png'),
        require('../../assets/img/sports/Pic_Bg_Fz_SRX.png'),
        require('../../assets/img/sports/Pic_Bg_Fz_SJDZ.png'),
        require('../../assets/img/sports/Pic_Bg_Fz_CLTX.png'),
      ],
      centerImgList:[
        // {
        //   id:1,
        //   url:require('../../assets/img/sports/lbt_03.png')
        // },
        {
          id:1,
          url:require('../../assets/img/sports/Pic_Sy_Lb_07.png')
        },
        {
          id:2,
          url:require('../../assets/img/sports/Pic_Sy_Lb_10.png')
        },
      ]
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper || this.$refs.mySwipertwo.swiper;
    },
  },
  mounted() {
    if (this.$store.state.spotrsindex) {
      this.getlist();
      this.newslistbtn(3); //轮播新闻
      this.teamtoday(); //今日参赛队伍
      this.newslistbtn(1); //赛事新闻
      this.newslistbtn(2); //花边新闻
    }
  },
  methods: {
    signup() {
      //  if(!this.$store.state.userInfo.token){
      //       this.$store.commit("setLoginState", ["login","/sport"]);
      //       return;
      //   }
      // if(this.$store.state.userInfo.teacherId){
      //   this.$notice({
      //     message: '请用园长账号报名',
      //   }).isShow();
      //   return;
      // }
      this.$router.push({
        path: "/signup",
      });
    },
    gonews(data) {
      this.$router.push({
        path: "/sportsnews",
        query: {
          id: data,
        },
      });
    },
    getlist() {
      console.log("3333");
      this.api.sports
        .projectlistspaots(this.$store.state.spotrsindex)
        .then((res) => {
          console.log(res);
          if (res.code == 20000) {
            this.contestTeamList = res.data;
          }
        });
    },
    gonewlist(id) {
      this.$router.push({
        path: "/sportDetail",
        query: {
          id: id,
        },
      });
    },
    newslistbtn(type) {
      //轮播新闻
      const that = this;
      var data = {
        contestAreaId: that.$store.state.spotrsindex,
        contestNewType: type,
      };
      that.api.sports.newslist(data).then((res) => {
        console.log(res);
        if (res.code == 20000) {
          if (type == 3) {
            //下面的轮播
            that.projectlist = res.data;
          } else if (type == 1) {
            //赛事
            that.Eventnews = res.data;
            that.eventTop = res.data.filter((item)=>{
              return item.showType == 2;
            })[0];
          } else if (type == 2) {
            //花边
            that.huabianlist = res.data;
            that.huabianTop = res.data.filter((item)=>{
              return item.showType == 2;
            })[0];
          }
        }
      });
    },
    teamtoday() {
      this.api.sports.teamtoday(this.$store.state.spotrsindex).then((res) => {
        console.log(res);
        if (res.code == 20000) {
          //今日参赛队伍
          this.teamtodaylist = res.data;
        }
      });
    },
    toJump(url){
      this.$store.commit('selectPage',url);
      this.$router.push({
        path: '/' + url,
      });
    }
  },
};
</script>
<style scoped lang="scss">
.gengduo {
  text-align: right;
  color: red;
  font-size: 20px;
  line-height: 34px;
}
.padding_40 {
  padding: 0 28px 0 40px;
  display: flex;
}
.recommendPage {
  margin-bottom: 10px;
  .swiper-container {
    width: 98%;

    box-sizing: border-box;
    padding: 30px;
    margin: 0 auto;
  }
}
.bg_white {
  background: white;
}
.felx_box {
  display: flex;
  flex-wrap: wrap;
}
.width_1434 {
  width: 1434px;
  margin: 0 auto;
}
.team-list{
  margin-left:-10px;
  margin-top:-22px;
  width:330px;
  >img{
    width:100%;
  }
}
.tabbox {
  height: 72px;
  background: linear-gradient(232deg, #3b60a8, #1f4283);
  padding-left: 39px;
  font-size: 20px;
  padding-top: 17px;
  box-sizing: border-box;

  .flxone {
    float: left;
  }
  .time {
    padding: 8px 10px;
    color: #ffffff;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;

    font-size: 20px;
  }
  .time:nth-last-child(1) {
    margin-right: 0;
  }
  .dis_inline {
    color: rgba(255, 255, 255, 0.4);
    margin: 0 10px;
    height: 42px;
    float: left;
    height: 42px;
    background: #ffffff;
    opacity: 0.4;
    border-radius: 1px;
    width: 1px;
    vertical-align: middle;
  }
  .time:hover {
    background: #122868;
    border-radius: 4px;
  }
  .fr_box {
    padding-right: 146px;
    .time {
      margin-left: 10px;
    }
  }
}

.swiper-slidebox {
  height: 252px;
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px rgba(75, 75, 75, 0.22);
  border-radius: 6px;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  width: 20px;
  height: 122px;
  background: #f2f2f2;
  border-radius: 2px;
  left: 0 !important;
  margin-top: -62px !important;
  justify-content: center;
  align-items: center;
  display: flex;
  opacity: 1 !important;
}
.swiper-button-prev:hover,
.swiper-button-next:hover {
  background: #bb3037ff;
  .el-icon-arrow-right {
    color: white;
  }
  .el-icon-arrow-left {
    color: white;
  }
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  width: 20px;
  /*--height: 122px;*/
  background: #f2f2f2;
  border-radius: 2px;
  right: 0 !important;
  margin-top: -62px !important;
  justify-content: center;
  align-items: center;
  display: flex;
  opacity: 1 !important;
}
.sloderbox {
  height: 94px;
  // background: #bb3037;
  border-radius: 6px 6px 0px 0px;
  padding: 16px 20px;
  box-sizing: border-box;
  background-size:100%;
  .time {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.7);
    font-weight: bold;
  }
  .felx_box {
    color: #ffffff;
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  .project-list{
    @include flex(row,space-between,flex-end);
    .project-list-time{
      margin-top:10px;
      font-size:18px;
      font-weight:normal;
      opacity:0.7
    }
    .project-list-hc{
      font-size:18px;
      font-weight:normal;
      opacity:0.7
    }
  }
}
.shool_name {
  margin: 20px;
  height: 130px;
  overflow: auto;

  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 16px;
  color: rgba(51, 51, 51, 1);
  font-family: Microsoft YaHei;
  // font-weight: bold;
  opacity: 0.7;
  line-height: 32px;
  @include flex(row,space-between,flex-start);
  >div{
    width:45%;
    white-space:nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
  }
}
.shool_name::-webkit-scrollbar {
 -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
  width:5px;
}
.img_box {
  width: 310px;
  margin-bottom: 30px;
}
.swperimg {
  width: 100%;
  height: 162px;
  border-radius: 6px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
}

.ranking {
  width: 310px;

  background: #ffffff;
  box-shadow: 0px 4px 12px 0px rgba(75, 75, 75, 0.22);
  border-radius: 6px;
  padding: 20px;
  box-sizing: border-box;
  .ranking_title {
    margin-bottom: 24px;
    i {
      width: 4px;
      height: 16px;
      background: #bb3037;
      border-radius: 2px;
      margin-right: 10px;
      display: inline-block;
    }
    span {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }
  }
}
.ringtime {
  font-size: 14px;
  color: #333333;
  text-align: center;
  width: 33%;
  .img_border {
    width: 54px;
    height: 54px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 50%;
    display: inline-block;
    overflow: hidden;
  }
  img {
    width: 40px;
    height: 52px;
  }
}
.padding_40right {
  margin-left: 22px;
  width:690px;
  h5 {
    font-weight: bold;
    color: #333333;
    font-size: 26px;
    margin-bottom: 8px;
  }
  .list_text {
    margin-top:15px;
    padding:0 20px 15px 20px;
    p {
      color: #333333;
      font-size: 20px;
      height:34px;
      line-height: 34px;
      width:650px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    p:hover {
      color: #bb3037;
      cursor: pointer;
    }
  }
  .list_textblack {
    font-weight: bold;
    color: #333333;
    font-size: 26px;
    margin-bottom: 8px;
  }
  
}
.rank-list{
  margin-top:-10px;
  margin-left:10px;
  width:332px;
  height:572px;
  >img{
    width:100%;
    height:100%;
    cursor:pointer;
  }
}
.margin_text_bottom {
  margin-bottom: 22px;
  width: 690px;
  // height: 252px;
  background: #F6F6F6;
  opacity: 0.9;
  border-radius: 2px;

  .margin_text_bottom-tit{
    padding: 0 20px;
    height: 48px;
    line-height: 48px;
    background: #BB3037;
    border-radius: 2px;
    @include flex(row, space-between, flex-start);

    >p:nth-child(1){
      font-size: 24px;
      font-weight: bold;
      color: #FFFFFF;
      width:580px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor:pointer;
    }
    >p:nth-child(2){
      padding-right:16px;
      font-size: 16px;
      font-weight: 400;
      color: #FFFFFF;
      background:url('../../assets/img/sports/Icon_Arrow_R.png') no-repeat right center;
      background-size:7px 12px;
      cursor:pointer;
    }
  }
}
.project-detail{
  margin-top:27px;
  height:555px;
  background:url('../../assets/img/sports/Pic_Bg_XMJS.png') no-repeat;
  background-size:100% 555px;
  .project-detail-tit{
    padding-top:40px;
    text-align:center;
    >img{
      width:286px;
      height:54px;
    }
  }
  .project-detail-dec{
    margin-top:10px;
    padding:0 20px;
    height:432px;
    >img{
      width:100%;
      height:100%;
      cursor:pointer;
    }
  }
}
</style>
  
<style >
.swiper-pagination-bullet {
  width: 12px;
  height: 2px;
  background: #ffffff;
  opacity: 0.3;
  border-radius: 1px;
}
.swiper-pagination-bullet-active {
  background: #ffffff;
  opacity: 1;
}
.signup {
  width: 100%;
  /* height: auto; */
  text-align: center;
  color: white;
  margin: 10px 0 30px 0 !important;
  line-height: 80px;
  font-size: 30px;
  cursor: pointer;
  /* background: url("../../assets/img/sports/Pic_LB_A.png") no-repeat left top; */
  /* background-size: cover; */
}
</style>